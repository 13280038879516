* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
}
body {
  background-color: $light-gray;
  margin: 0;
}
input, textarea {
  background: $gray-bg;
  border-radius: 3px;
  border: 0;
  outline: none;
  color: $base-color;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
button {
  background: none;
  border: 0;
  outline: none;
  cursor: pointer;
}
.container {
  max-width: 1370px;
  width: 100%;
  padding: 0 15px;
  margin: auto;
}
