
.file-drop-target, .box {
  font-size: 1.25rem; /* 20 */
  position: relative;
  padding: 80px 20px;
  background: #FBFDFF;
  border-radius: 3px;
  &__dragndrop, &__uploading, &__success, &__error, &__file, &__icon {
    display: none;
    text-align: center;
  }
  &-label {
    display: table;
    margin: auto;
    font-weight: 600;
    font-size: 14px;
    color: $dark-gray;
  }
  &__input {
    svg {
      margin: auto;
      display: block;
    }
  }
  &__button {
    font-weight: 700;
    color: #e5edf1;
    background-color: #39bfd3;
    display: none;
    padding: 8px 16px;
    margin: 40px auto 0;
    &:hover, &:focus {
      background-color: #0f3c4b;
    }
  }
  &.file-drop-dragging-over-frame{
    .upload-content__drag-drop,.upload-content__files{
      border: 3px dashed #7AB8EC;
      background: #FBFDFF;
    }
  }
  &.has-advanced-upload
  {
    border: 3px dashed #7AB8EC;
    .box__dragndrop {
      display: inline;
    }
    .box__icon {
      width: 100%;
      height: 80px;
      fill: #92b0b3;
      display: block;
      margin-bottom: 40px;
    }
  }
  &.is-dragover {
    //outline-offset: -20px;
    //outline-color: #c8dadf;
    //background-color: grey;
  }
}
.box.is-uploading .box__input,
.box.is-success .box__input,
.box.is-error .box__input
{
  visibility: hidden;
}
.box.is-uploading .box__uploading,
.box.is-success .box__success,
.box.is-error .box__error {
  display: block;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  -webkit-transform: translateY( -50% );
  transform: translateY( -50% );
}
.box__uploading {
  font-style: italic;
}
.box__success {
  -webkit-animation: appear-from-inside .25s ease-in-out;
  animation: appear-from-inside .25s ease-in-out;
}
@-webkit-keyframes appear-from-inside
{
  from	{ -webkit-transform: translateY( -50% ) scale( 0 ); }
  75%		{ -webkit-transform: translateY( -50% ) scale( 1.1 ); }
  to		{ -webkit-transform: translateY( -50% ) scale( 1 ); }
}
@keyframes appear-from-inside
{
  from	{ transform: translateY( -50% ) scale( 0 ); }
  75%		{ transform: translateY( -50% ) scale( 1.1 ); }
  to		{ transform: translateY( -50% ) scale( 1 ); }
}

.box__restart {
  font-weight: 700;
  &:focus, &:hover {
    color: #39bfd3;
  }
}

.js {
  .box__file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    &+ label
    {
      max-width: 80%;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      display: inline-block;
      overflow: hidden;
    }
  }
}
.js .box__file + label:hover strong,
.box__file:focus + label strong,
.box__file.has-focus + label strong {
  color: #39bfd3;
}
.no-js {
  .box__file {
    &:focus, &.has-focus {
      &+ label {
        outline: 1px dotted #000;
        outline: -webkit-focus-ring-color auto 5px;
      }
    }
    &+ label * {
      /* pointer-events: none; */ /* in case of FastClick lib use */
    }
    &+ label {
      display: none;
    }
  }
  .box__button {
    display: block;
  }
}
.file-drop-target{
  background: none;
  font-size:inherit; /* 20 */
  position: inherit;
  padding: 0;
  border-radius: inherit;
}
