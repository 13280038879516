.upload-content {
  display: flex;
  flex-direction: row;
  padding: 20px 0;
  .container {
    display: flex;
  }
  &__wrapper {
    padding: 40px 40px 100px 40px;
    margin: 0 15px 0 0;
    background: $white;
    border: 1px solid $green-text;
    box-sizing: border-box;
    box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    width: calc(100% - 452px);
    h3 {
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $base-color;
      margin: 0 0 15px;
    }
  }
  &__drag-drop {
    width: 100%;
    background: $gray-bg;
    border-radius: 10px;
    padding: 96px 15px 10px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 86px;
      left: 0;
      right: 0;
      height: 1px;
      background: $gray-text;
      opacity: 0.4;
    }
    &__old {
      padding: 80px 0 30px;
      &:after {
        display: none;
      }
      .upload-content__buttons {
        margin: 0;
        padding: 30px 0 0;
        .button-green {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          svg {
            margin: 0 20px 0 0;
          }
        }
      }
    }
    p {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: $dark-gray;
      text-align: center;
      span {
        display: block;
      }
    }
  }
  &__buttons {
    justify-content: space-around;
    display: flex;
    width: 100%;
    margin: 50px 0 0;
    padding: 30px 40px 0;
    border-top: solid 1px rgba(157, 157, 157, 0.4);
    box-sizing: border-box;

    button, label {
      width: 221px;
      height: 40px;
      &:nth-of-type(1) {
        align-self: flex-start;
      }
      &:nth-of-type(2) {
        align-self: flex-end;
      }
      svg {
        position: relative;
        margin: 1px 20px -2px 0;
      }
    }
    label {
      text-align: center;
      padding-top: 8px;
      cursor: pointer;
    }
  }
  &__text-edit {
    .form-group {
      position: relative;
      margin: 0 0 10px;
      svg {
        float: left;
        position: absolute;
        top: 0;
        left: 0;
      }
      input {
        width: 100%;
        height: 40px;
        padding: 0 10px 0 50px;
        box-sizing: border-box;
        font-size: 16px;
        font-weight: 600;
        border-radius: 3px;
      }
      textarea {
        width: 100%;
        height: 200px;
        padding: 15px 25px;
        box-sizing: border-box;
        font-size: 14px;
        resize: none;
        border-radius: 3px;
      }
    }
    &__buttons {
      margin-bottom: -20px;
      button {
        width: 96px;
        height: 34px;
        margin-right: 5px;
        &.button {
          &.button-green {
            box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
            border: 1px solid transparent;
            transition: none;
            &:hover {
              box-shadow: none;
              border-color: $green-text;
            }
            &:focus, &:active {
              box-shadow: none;
              border: 2px solid rgba(14, 125, 125, 0.3);
            }
          }
        }
      }
    }
  }
  &__files {
    display: flex;
    flex-direction: row;
    background: $gray-bg;
    border-radius: 10px;
    padding: 75px 0 0;
    margin-bottom: -45px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 75px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(157, 157, 157, 0.4);
    }
}
  &__files-list {
    width: calc(100% - 150px);
    margin: 20px 0;
    padding: 0 20px;
    .form-group {
      background-color: $white;
      border-radius: 7px;
      position: static;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      &:not(:last-child) {
        margin: 0 0 10px;
      }
      p {
        width: calc(100% - 216px);
      }
      .upload-content__files-list__options {
        width: 23px;
        &-edit {
          width: 50px;
        }
      }
      &.has-manual {
        p {
          width: calc(100% - 228px);
        }
      }
      &.has-edit {
        p {
          width: calc(100% - 246px);
        }
      }
      svg {
        display: inline-block;
        position: relative;
        z-index: 2;
      }
      p {
        display: inline-block;
        margin: 0;
        padding: 0 30px;
        font-size: 14px;
        color: $base-color;
        width: calc(100% - 218px);
        box-sizing: border-box;
        @include truncate();
        @include transition(all, 0.2s);
      }
      span {
        width: 145px;
        text-align: right;
        &.in-quote {
          font-weight: 600;
          font-size: 12px;
          line-height: 14px;
          color: $text-blue;
        svg {
            position: relative;
            margin: 0 0 -6px;
          }
        }
        &.upload-edit {
          b {
            font-weight: 600;
            font-size: 12px;
            color: $base-color;
            span {
              color: $gray-text;
              width: auto;
              margin: 0 0 0 5px;
            }
          }
        }
        &.uploading {
          b {
            color: $text-blue;
            font-weight: 600;
            font-size: 12px;
            span {
              width: auto;
              margin: 0 0 0 5px;
              color: $gray-text;
            }
          }
          .loading:after {
            content: '.';
            animation: dots 1s steps(5, end) infinite;
          }
          .loading-blue{
            margin-right: 5px;
            &:after {
              content: '.';
              animation: dots-blue 1s steps(5, end) infinite;
            }
          }
        }

        button {
          font-weight: 600;
          font-size: 12px;
          color: $gray-text;
        }
      }
      div {
          &.upload-manual {
            font-size: 12px;
            font-weight: 400;
            position: relative;
            width: auto;
            text-align: left;
            margin-left: auto;
            // margin-right: 10px;
            @include transition(all, 600ms);
            // transition: all 0.4s ease;
            .btn-preview-toggle {
               width: 51px;
              height: 19px;
              background: $picton-blue;
              border-radius: 10px;
              padding: 0;
              margin: 0 0 0 7px;
              position: relative;

              &:hover {
                  background: $steel-blue;
              }

              &__text {
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 19px;
                  color: white;
                  text-align: center;
                  display: block;
                  position: relative;
                  padding-right: 17px;
                  transition: all 0.4s ease;

                  &::before {
                      content: "";
                      width: 17px;
                      height: 17px;
                      position: absolute;
                      transition: all 0.4s ease;
                      right: 1px;
                      top: 1px;
                      border-radius: 50%;
                      background: rgba(0, 0, 0, 0.25) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2IiBoZWlnaHQ9IjEzIiBmaWxsPSJub25lIj48cGF0aCBkPSJNNS42NjUgMTAuODQ3bC0uMTU1LjYzMy0xLjExMi40MTljLS4yNzcuMDk2LS41OTguMTQ0LS45NjUuMTQ0LS41NjMgMC0xLS4xMzgtMS4zMTItLjQxMmExLjMzIDEuMzMgMCAwIDEtLjQ2OC0xLjA0NiAzLjcgMy43IDAgMCAxIC4wMzUtLjUwM2MuMDI0LS4xNzIuMDYyLS4zNjUuMTEzLS41ODFsLjU4MS0yLjA1Ni4xMzEtLjU2Yy4wMzYtLjE3NS4wNTMtLjMzNy4wNTMtLjQ4MiAwLS4yNjMtLjA1NC0uNDQ2LS4xNjMtLjU0OVMyLjA4OCA1LjcgMS43ODEgNS43YTEuNjMgMS42MyAwIDAgMC0uNDYyLjA3MWwtLjQwNi4xMzQuMTU1LS42MzQgMS4wOTItLjM5OGMuMzQ4LS4xMS42NzctLjE2Ni45ODgtLjE2Ni41NTkgMCAuOTkuMTM1IDEuMjkzLjQwNXMuNDU0LjYyMS40NTQgMS4wNTJjMCAuMDg5LS4wMS4yNDctLjAzMS40NzJzLS4wNi40MzItLjExNi42MmwtLjU3OCAyLjA0N2MtLjA0Ny4xNjQtLjA5LjM1Mi0uMTI3LjU2NHMtLjA1Ny4zNzEtLjA1Ny40NzhjMCAuMjcyLjA2MS40NTcuMTgyLjU1NnMuMzMzLjE0OC42MzMuMTQ4Yy4xNDEgMCAuMzAxLS4wMjUuNDc4LS4wNzRzLjMwNy0uMDkyLjM4Ny0uMTN6bS4xNDctOC41OTZjMCAuMzU3LS4xMzQuNjYxLS40MDUuOTEycy0uNTk0LjM3Ny0uOTc0LjM3N2ExLjM5IDEuMzkgMCAwIDEtLjk3OS0uMzc3Yy0uMjcyLS4yNTEtLjQwOC0uNTU1LS40MDgtLjkxMnMuMTM2LS42NjEuNDA4LS45MTUuNTk4LS4zOC45NzktLjM4LjcwNS4xMjcuOTc0LjM4YTEuMjEgMS4yMSAwIDAgMSAuNDA1LjkxNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=') center no-repeat;
                  }
              }

              &__text:hover::before {
                  background-color: $steel-blue;
              }
            }
            svg {
              margin: 0 0 -4px 0;
              cursor: pointer;
            }
          }
      }
      .button-edit {
        font-weight: 600;
        font-size: 12px;
        color: $gray-text;
        border-radius: 3px;
        margin-right: 3px;
        width: 23px;
        height: 23px;
        display: inline-block;
        vertical-align: middle;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTkuMTEyMDMgMi41MDMzMUwxMi4wOTI5IDUuNTY5NTZMNC41NDc0NiAxMy4zMzEyTDEuNTY4MjYgMTAuMjY0OUw5LjExMjAzIDIuNTAzMzFaTTE0LjM1NDggMS43NjM4TDEzLjAyNTUgMC4zOTYzNTJDMTIuNTExNyAtMC4xMzIxMTcgMTEuNjc3NSAtMC4xMzIxMTcgMTEuMTYyIDAuMzk2MzUyTDkuODg4NiAxLjcwNjIzTDEyLjg2OTUgNC43NzI1MUwxNC4zNTQ4IDMuMjQ0NjFDMTQuNzUzMyAyLjgzNDcgMTQuNzUzMyAyLjE3MzY4IDE0LjM1NDggMS43NjM4Wk0wLjAwODI5NTEyIDE0LjU3NDhDLTAuMDQ1OTUzMyAxNC44MjU5IDAuMTc0NDc2IDE1LjA1MSAwLjQxODY0OSAxNC45ODk5TDMuNzQwMzUgMTQuMTYxNEwwLjc2MTE1MSAxMS4wOTUyTDAuMDA4Mjk1MTIgMTQuNTc0OFoiIGZpbGw9IiM5RDlEOUQiLz4KPC9zdmc+Cg==') center no-repeat;
        background-size: 13px auto;
        transition: all 0.4s ease;
        margin-right: 4px;
        flex-shrink: 0;

        svg {
            display: none;
        }

        &:hover {
            background: $rose-white url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTkuMTEyIDIuNTAzbDIuOTgxIDMuMDY2LTcuNTQ1IDcuNzYyLTIuOTc5LTMuMDY2IDcuNTQ0LTcuNzYyem01LjI0My0uNzRMMTMuMDI2LjM5NmMtLjUxNC0uNTI4LTEuMzQ4LS41MjgtMS44NjMgMGwtMS4yNzMgMS4zMSAyLjk4MSAzLjA2NiAxLjQ4NS0xLjUyOGMuMzk5LS40MS4zOTktMS4wNzEgMC0xLjQ4MXpNLjAwOCAxNC41NzVjLS4wNTQuMjUxLjE2Ni40NzYuNDEuNDE1bDMuMzIyLS44MjgtMi45NzktMy4wNjYtLjc1MyAzLjQ4eiIgZmlsbD0iIzNmODhjNSIvPjwvc3ZnPg==') center no-repeat;
            background-size: 13px auto;
        }

        &:active {
            border: 1px solid $text-blue;
            background: $rose-white url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0ibm9uZSI+PHBhdGggZD0iTTkuMTEyIDIuNTAzbDIuOTgxIDMuMDY2LTcuNTQ1IDcuNzYyLTIuOTc5LTMuMDY2IDcuNTQ0LTcuNzYyem01LjI0My0uNzRMMTMuMDI2LjM5NmMtLjUxNC0uNTI4LTEuMzQ4LS41MjgtMS44NjMgMGwtMS4yNzMgMS4zMSAyLjk4MSAzLjA2NiAxLjQ4NS0xLjUyOGMuMzk5LS40MS4zOTktMS4wNzEgMC0xLjQ4MXpNLjAwOCAxNC41NzVjLS4wNTQuMjUxLjE2Ni40NzYuNDEuNDE1bDMuMzIyLS44MjgtMi45NzktMy4wNjYtLjc1MyAzLjQ4eiIgZmlsbD0iIzNmODhjNSIvPjwvc3ZnPg==') center no-repeat;
            background-size: 13px auto;
        }
      }
      .button-close {
          border-radius: 3px;
          width: 23px;
          height: 23px;
          display: inline-block;
          vertical-align: middle;
          flex-shrink: 0;
          background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iIzlkOWQ5ZCI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=') center no-repeat;
          transition: all 0.4s ease;
          background-size: 13px auto;

        svg {
          top: 1px;
          display: none;
        }

        &:hover {
            background: rgba(255, 91, 91, 0.1) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iI2ZmNWI1YiI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=') center no-repeat;
            background-size: 13px auto;
        }

        &:active {
            background: rgba(255, 91, 91, 0.1) url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSIxNSIgZmlsbD0iI2ZmNWI1YiI+PHBhdGggZD0iTTE0LjY1OSAxMy4xOTNMMTIuODUxIDE1IDAgMi4xNDkgMS44MDcuMzQxeiIvPjxwYXRoIGQ9Ik0xMi44NTIgMGwxLjgwNyAxLjgwN0wxLjgwOCAxNC42NTkuMDAxIDEyLjg1MXoiLz48L3N2Zz4=') center no-repeat;
            border: 1px solid $red-text;
            background-size: 13px auto;
        }
      }
      .uploading-loadbar {
        // width: calc(100% - 37px);
        // flex: 0 1 calc(100% - 37px);
        // margin-left: 32px;
        border-radius: 0 0 7px 0;
        overflow: hidden;
        position: absolute;
        left: 32px;
        bottom: 0;
        right: 2px;
      }
      .upload-manual-review {
        background: $white;
        box-shadow: 3px 5px 30px rgba(0, 0, 0, 0.05);
        border-radius: 10px;
        padding: 20px 20px 16px;
        width: 238px;
        position: absolute;
        left: 7px;
        bottom: 22px;
        z-index: 9;
        display: none;
        &-visible {
          display: block;
        }
        b {
          font-size: 14px;
          font-weight: 600;
          color: $base-color;
          margin-bottom: 10px;
          display: block;
        }
        p {
          font-size: 12px;
          color: $base-color;
          padding: 0;
          width: auto!important;
          white-space: inherit;
        }
      }
    }
    &__options {
      width: 0;
      display: none;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      overflow: hidden;
      @include transition(all, 200ms);
      transition: all 0.4s ease;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -(23px / 2);

      &-edit {
        width: 0;
      }

      .form-group_hint & {
          right: -35px;
          display: flex;
          transition: all 0.4s ease;
      }
    }

    .form-group {
        &__buttons {
            overflow: hidden;
            width: 0;
            padding-right: 10px;
            transition: all 0.4s ease;
            position: relative;
            height: 23px;
        }

        &:hover {
            box-shadow: 0 0 0 1px $glacier;

            & > svg,
            & > img

            {
                border-radius: 7px 0 0 7px;
            }
        }
    }

    .form-group_hint:hover &__options {
        right: 10px;
    }

    .form-group_hint:hover .form-group__buttons {
        width: 40px;
        padding-right: 10px;
    }

    .form-group:hover &__options {
        display: flex;
    }

    .form-group {
        position: relative;
        flex-wrap: nowrap;

        &_loadbar {
            padding-bottom: 3px;
        }

        .uploading,
        .in-quote,
        .upload-edit {
            margin-right: 10px;
        }
    }

    .form-group:hover {
        .uploading,
        .in-quote,
        .upload-edit {
            display: none;
        }
    }
  }
  &__files-upload__button {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 0;
    button {
      padding: 6px 12px;
      margin-left: -10px;
      position: relative;
      &.button {
        &.button-green {
          font-weight: 600;
          font-size: 14px;
          &:hover {
            color: $dark-green;
            border-color: $dark-green;
            background: $white;
            svg {
              path {
                fill: $dark-green;
                stroke: none;
              }
            }
          }
          &:focus, &.active {
            border-color: rgba(14, 125, 125, 0.3);
            color: $green-text;
            background: $white;
            box-shadow: none;
            svg {
              path {
                fill: $green-text;
                stroke: none;
              }
            }
          }
        }
      }
      svg {
        &:nth-of-type(1) {
          margin: 0 10px 0 0;
        }
        &:nth-of-type(2) {
          margin: 0 0 0 10px;
          top: -2px;
          position: relative;
        }
      }
    span {
        display: block;
      }
    }
    .dropdown {
      display: none;
      position: absolute;
      padding: 5px;
      border: 0;
      top: calc(100% + 10px);
      right: 0;
      background: $white;
      box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
      border-radius: 3px;
      opacity: 9;
      &.active {
        display: block;
      }
      ul {
        li {
          display: block;
          a {
            white-space: nowrap;
            text-align: left;
            display: block;
            color: $green-text;
            border-radius: 3px;
            padding: 8px 6px;
            border: solid 1px transparent;
            &:hover {
              background: rgba(14, 123, 123, 0.1);
            }
            &:active {
              border: 1px solid #B7D8D8;
              background: $white;
            }
            svg {
              margin-bottom: -5px;
            }
          }
        }
      }
    }
  }
}
.summary {
  width: 437px;
  height: fit-content;
  background: $white;
  border: 1px solid $green-text;
  border-top: 7px solid $green-text;
  box-sizing: border-box;
  box-shadow: 5px 10px 40px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 10px 10px;
  h2 {
    font-weight: bold;
    font-size: 30px;
    line-height: 41px;
    text-align: center;
    margin: 0;
    padding: 25px 0;
    border-bottom: solid 1px $gray-text;
    color: $green-text;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: $gray-text;
    text-align: center;
    padding: 60px 15px;
  }
  ul {
    padding: 25px 10px;
    text-align: center;
    li {
      padding: 5px 20px;
      &:nth-of-type(1) {
        span {
          min-height: 1px;
          font-size: 12px;
          line-height: 17px;
        }
      }
      span {
        &:not(:first-child) {
          &:hover {
            background: rgba(0, 150, 136, 0.1);
            border-radius: 3px;
            cursor: pointer;
            color: $green-text;
          }
        }
       }
      &.summary-total {
        border-top: solid 1px $green-border;
        margin: 10px 0 0;
        span {
          font-weight: bold;
          font-size: 16px;
          color: $green-text;
        }
      }
      span {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: $base-color;
        display: inline-block;
        padding: 5px 0;
        @include transition(all, 200ms);
        &:nth-of-type(1) {
          width: 25%;
          text-align: left;
        }
        &:nth-of-type(2) {
          width: 20%;
        }
        &:nth-of-type(3) {
          width: 30%;
        }
        &:nth-of-type(4) {
          width: 20%;
        }
      }
    }
  }
}

.wrap-btn-add {
    justify-content: space-around;
    display: flex;
    width: 100%;
    padding: 30px 40px 1px;
    border-top: solid 1px rgba(157, 157, 157, 0.4);
}

.btn-add {
    position: relative;
    display: inline-block;
    vertical-align: middle;

    &_second &__header &__input-label {
        padding-right: 15px;
        padding-left: 45px;
    }

    &__header {
        box-shadow: inset 0 0 0 1px $green-text, 0 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        background: $white;
    }

    &__input {
        position: relative;

        input[type="file"] {
            display: none;
        }

        &-label {
            border: none;
            color: $green-text;
            text-align: left;
            font-size: 14px;
            line-height: 1.5;
            font-weight: 600;
            display: block;
            padding: 8px 10px 9px 40px;
            cursor: pointer;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNyAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE1LjY3MzIgNy44MjUzNUMxNS40MzI3IDcuODI1MzUgMTUuMjQwNCA4LjAxNzcgMTUuMjQwNCA4LjI1ODE0VjEyLjE5NDlDMTUuMjQwNCAxMy4yNjU3IDE0LjM2ODQgMTQuMTM0NSAxMy4zMDA4IDE0LjEzNDVIMy44MDUxMkMyLjczNDM2IDE0LjEzNDUgMS44NjU1OCAxMy4yNjI1IDEuODY1NTggMTIuMTk0OVY4LjE5NDAyQzEuODY1NTggNy45NTM1OCAxLjY3MzIzIDcuNzYxMjMgMS40MzI3OSA3Ljc2MTIzQzEuMTkyMzUgNy43NjEyMyAxIDcuOTUzNTggMSA4LjE5NDAyVjEyLjE5NDlDMSAxMy43NDMzIDIuMjU5OSAxNSAzLjgwNTEyIDE1SDEzLjMwMDhDMTQuODQ5MyAxNSAxNi4xMDU5IDEzLjc0MDEgMTYuMTA1OSAxMi4xOTQ5VjguMjU4MTRDMTYuMTA1OSA4LjAyMDkgMTUuOTEzNiA3LjgyNTM1IDE1LjY3MzIgNy44MjUzNVoiIGZpbGw9IiMwRTdEN0QiIHN0cm9rZT0iIzBFN0Q3RCIgc3Ryb2tlLXdpZHRoPSIwLjUiLz4KPHBhdGggZD0iTTYuMTA2OSA0LjQ5MTE3TDguMTIwMTggMi40Nzc5VjExLjI1ODdDOC4xMjAxOCAxMS40OTkxIDguMzEyNTMgMTEuNjkxNSA4LjU1Mjk3IDExLjY5MTVDOC43OTM0IDExLjY5MTUgOC45ODU3NSAxMS40OTkxIDguOTg1NzUgMTEuMjU4N1YyLjQ3NzlMMTAuOTk5IDQuNDkxMTdDMTEuMDgyNCA0LjU3NDUyIDExLjE5NDYgNC42MTk0IDExLjMwMzYgNC42MTk0QzExLjQxNTggNC42MTk0IDExLjUyNDggNC41Nzc3MiAxMS42MDgxIDQuNDkxMTdDMTEuNzc4IDQuMzIxMjYgMTEuNzc4IDQuMDQ4NzYgMTEuNjA4MSAzLjg3ODg1TDguODU3NTIgMS4xMjgyM0M4Ljc3NzM3IDEuMDQ4MDkgOC42NjUxNyAxIDguNTUyOTcgMUM4LjQzNzU2IDEgOC4zMjg1NiAxLjA0NDg4IDguMjQ4NDEgMS4xMjgyM0w1LjQ5Nzc5IDMuODc4ODVDNS4zMjc4OCA0LjA0ODc2IDUuMzI3ODggNC4zMjEyNiA1LjQ5Nzc5IDQuNDkxMTdDNS42NjQ1IDQuNjU3ODcgNS45NDAyIDQuNjU3ODcgNi4xMDY5IDQuNDkxMTdaIiBmaWxsPSIjMEU3RDdEIiBzdHJva2U9IiMwRTdEN0QiIHN0cm9rZS13aWR0aD0iMC41Ii8+Cjwvc3ZnPgo=') 10px center no-repeat;
        }

        &_add &-label {
            color: $green-text;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiIGZpbGw9IiMwRTdEN0QiLz4KPHBhdGggZD0iTTE2IDdMMTYgOUwtOC43NDIyOGUtMDggOUwwIDdMMTYgN1oiIGZpbGw9IiMwRTdEN0QiLz4KPC9zdmc+Cg==') 19px center no-repeat;
            border-radius: 4px;
            height: 45px;
            line-height: 45px;
            padding: 0 31px 0 55px;
            transition: all 0.4s ease;

            &:hover {
                color: $dark-green;
                box-shadow: inset 0 0 0 2px #0E7D7D;
                background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzBhNjE2MSI+PHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiLz48cGF0aCBkPSJNMTYgN3YySDBWN2gxNnoiLz48L3N2Zz4=') 19px center no-repeat;
            }

            &:active {
                color: $dark-green;
                box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
                background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iIzBhNjE2MSI+PHBhdGggZD0iTTcgMEg5VjE2SDdWMFoiLz48cGF0aCBkPSJNMTYgN3YySDBWN2gxNnoiLz48L3N2Zz4=') 19px center no-repeat;
            }
        }
    }

    &__dropdown &__input-label {
        border: 1px solid $white;
        border-radius: 3px;

        &:hover {
            background-color: rgba(14, 123, 123, 0.1);
        }

        &:active {
            background-color: $white;
            border-color: $scandal-color;
        }
    }

    &__icon {
        width: 45px;
        height: 45px;
        cursor: pointer;
        border-radius: 4px;
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBFN0Q3RCIvPgo8L3N2Zz4K') center no-repeat;
        transition: all 0.4s ease;
        position: relative;

        &:hover {
            box-shadow: inset 0 0 0 2px #0E7D7D;
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K') center no-repeat;
        }

        &:active {
            box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K') center no-repeat;
        }
    }

    &_opened &__icon {
        box-shadow: inset 0 0 0 3px rgba(14, 125, 125, 0.3);
        background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDExIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik01LjUgNi43NzUxM0wwIDAuODUyMDUxSDExTDUuNSA2Ljc3NTEzWiIgZmlsbD0iIzBBNjE2MSIvPgo8L3N2Zz4K') center no-repeat;
    }

    &__link {
        display: block;
        padding: 8px 10px 9px 40px;
        border-radius: 3px;
        border: 1px solid $white;
        transition: all 0.4s ease;
        position: relative;
        font-weight: 600;
        font-size: 14px;
        line-height: 21px;
        color: $green-text;
        text-decoration: none;

        &::before {
            content: "";
            position: absolute;
            left: 10px;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-top: -10px;
        }

        &_text::before {
            background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIxOSIgZmlsbD0iIzBlN2Q3ZCIgIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIuMSI+PHBhdGggZD0iTTE5LjU1IDEyLjMyMmEuNjkyLjY5MiAwIDEgMS0xLjM4NCAwVjIuOTY5YzAtLjg0Ni0uNjg4LTEuNTM0LTEuNTM0LTEuNTM0SDMuNTY5Yy0uODQ2IDAtMS41MzQuNjg4LTEuNTM0IDEuNTM0djEzLjA2MmMwIC44NDYuNjg4IDEuNTM0IDEuNTM0IDEuNTM0aDEzLjA2MmMuODQ2IDAgMS41MzQtLjY4OCAxLjUzNC0xLjUzNGEuNjkyLjY5MiAwIDAgMSAxLjM4NCAwYzAgMS42MDktMS4zMDkgMi45MTktMi45MTkgMi45MTlIMy41NjljLTEuNjEgMC0yLjkxOS0xLjMwOS0yLjkxOS0yLjkxOVYyLjk2OUMuNjUgMS4zNTkgMS45NTkuMDUgMy41NjkuMDVoMTMuMDYyYzEuNjA5IDAgMi45MTkgMS4zMDkgMi45MTkgMi45MTl2OS4zNTR6bS0zLjY2MS0zLjUxNGEuNjkyLjY5MiAwIDEgMSAwIDEuMzg0SDQuMzQ4YS42OTIuNjkyIDAgMSAxIDAtMS4zODRoMTEuNTQxek0zLjY1NiAxMi40NjlhLjY5LjY5IDAgMCAxIC42OTItLjY5Mmg2LjkzOWEuNjkyLjY5MiAwIDEgMSAwIDEuMzg0SDQuMzQ4YS42OS42OSAwIDAgMS0uNjkyLS42OTJ6bTkuMTE2LTUuMjQ2SDQuMzQ4YS42OTIuNjkyIDAgMSAxIDAtMS4zODRoOC40MjRhLjY5Mi42OTIgMCAxIDEgMCAxLjM4NHoiLz48L3N2Zz4=') center no-repeat;
        }

        &_url::before {
            background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTkiIHZpZXdCb3g9IjAgMCAxOSAxOSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEuMTk0OTUgMTcuODA1MkwxLjE5NDk2IDE3LjgwNTJDMi43MjEwNCAxOS4zMzIzIDUuMTk1MzcgMTkuMzMyMyA2LjcyMjQ3IDE3LjgwNTJMOC45NjEzOCAxNS41NjYzQzkuMjUxMDMgMTUuMjc2NiA5LjI1MTAzIDE0LjgwNyA4Ljk2MTM4IDE0LjUxNzRDOC42NzE3NCAxNC4yMjc3IDguMjAyMDggMTQuMjI3OCA3LjkxMjQzIDE0LjUxNzRMNS42NzM1MiAxNi43NTYzQzQuNzI1OCAxNy43MDQgMy4xOTA5OCAxNy43MDQxIDIuMjQ0MDEgMTYuNzU2NUwxLjE5NDk1IDE3LjgwNTJaTTEuMTk0OTUgMTcuODA1MkMtMC4zMzEzMjQgMTYuMjc4OSAtMC4zMzEzMjQgMTMuODA0OCAxLjE5NDk1IDEyLjI3ODVMNS42NzM1MiA3Ljc5OTkzQzcuMTk5NzkgNi4yNzM2NiA5LjY3Mzk1IDYuMjczNjYgMTEuMjAwMiA3Ljc5OTkzQzExLjQ4OTkgOC4wODk1NyAxMS40ODk5IDguNTU5MiAxMS4yMDAyIDguODQ4ODRDMTAuOTEwNiA5LjEzODQ4IDEwLjQ0MSA5LjEzODQ4IDEwLjE1MTMgOC44NDg4NEM5LjIwNDM2IDcuOTAxODUgNy42NjkzOSA3LjkwMTg1IDYuNzIyNDMgOC44NDg4NEwyLjI0MzgzIDEzLjMyNzRDMS4yOTY4NyAxNC4yNzQ0IDEuMjk2ODcgMTUuODA5NCAyLjI0NCAxNi43NTY1TDEuMTk0OTUgMTcuODA1MloiIGZpbGw9IiMwRTdEN0QiIHN0cm9rZT0id2hpdGUiIHN0cm9rZS13aWR0aD0iMC4xIi8+CjxwYXRoIGQ9Ik0xMi4yNzggMS4xOTQ3MUMxMy44MDQzIC0wLjMzMTU2OSAxNi4yNzkyIC0wLjMzMTU2OSAxNy44MDU1IDEuMTk0NzFDMTkuMzMxOCAyLjcyMDk4IDE5LjMzMTggNS4xOTUxNyAxNy44MDU1IDYuNzIxNDVMMTIuODc5NiAxMS42NDczQzExLjM1MzQgMTMuMTczNiA4Ljg3OTIgMTMuMTczNiA3LjM1MjkyIDExLjY0NzNDNy4wNjMyOCAxMS4zNTc3IDcuMDYzMjggMTAuODg4MSA3LjM1MjkyIDEwLjU5ODRDNy42NDI1NyAxMC4zMDg4IDguMTEyMiAxMC4zMDg4IDguNDAxODQgMTAuNTk4NEM5LjM0ODc5IDExLjU0NTQgMTAuODgzNyAxMS41NDU0IDExLjgzMDcgMTAuNTk4NEwxNi43NTY2IDUuNjcyNUMxNy43MDM2IDQuNzI1NTQgMTcuNzAzNiAzLjE5MDU3IDE2Ljc1NjYgMi4yNDM2MkMxNS44MDk2IDEuMjk2NjMgMTQuMjczOSAxLjI5NjYzIDEzLjMyNjkgMi4yNDM2MkwxMC42NDA3IDQuOTI5ODNDMTAuMzUxMSA1LjIxOTQ3IDkuODgxNDQgNS4yMTk0NyA5LjU5MTggNC45Mjk4M0M5LjMwMjE2IDQuNjQwMTggOS4zMDIxNiA0LjE3MDU1IDkuNTkxOCAzLjg4MDkxTDEyLjI3OCAxLjE5NDcxWiIgZmlsbD0iIzBFN0Q3RCIgc3Ryb2tlPSJ3aGl0ZSIgc3Ryb2tlLXdpZHRoPSIwLjEiLz4KPC9zdmc+Cg==') center no-repeat;
        }

        &:hover {
            background: rgba(14, 123, 123, 0.1);
        }

        &:active {
            background-color: $white;
            border-color: $scandal-color;
            text-decoration: none;
        }
    }

    &__dropdown {
        position: absolute;
        right: 0;
        top: 100%;
        display: none;
        background: $white;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.05);
        border-radius: 3px;
        min-width: 100%;
        padding: 5px;
        margin-top: 7px;
        white-space: nowrap;
    }

    &_opened &__dropdown {
        display: block;
    }
}
