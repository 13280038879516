.meter {
  height: 3px;
  &> span {
    display: block;
    height: 100%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background-color: $light-green;
    background-image: linear-gradient(
                    center bottom,
                    rgb(43, 194, 83) 37%,
                    rgb(84, 240, 84) 69%
    );
    box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
  }
}
.meter > span:after,
.animate > span > span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
                  -45deg,
                  rgba(255, 255, 255, 0.7) 25%,
                  transparent 25%,
                  transparent 50%,
                  rgba(255, 255, 255, 0.7) 50%,
                  rgba(255, 255, 255, 0.7) 75%,
                  transparent 75%,
                  transparent
  );
  z-index: 1;
  background-size: 50px 50px;
  animation: move 2s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate > span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.progress-bar {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: rgba(0, 208, 108, 0.2);
  height: 3px;
  .progress-percentage {
    height: 100%;
    background: $light-green;
  }
}
