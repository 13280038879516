@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
            .25em 0 0 $white,
            .5em 0 0 $white;}
  40% {
    color: $gray-text;
    text-shadow:
            .25em 0 0 $white,
            .5em 0 0 $white;}
  60% {
    text-shadow:
            .25em 0 0 $gray-text,
            .5em 0 0 $white;}
  80%, 100% {
    text-shadow:
            .25em 0 0 $gray-text,
            .5em 0 0 $gray-text;}
}

@keyframes dots-blue {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
            .25em 0 0 $white,
            .5em 0 0 $white;}
  40% {
    color: $text-blue;
    text-shadow:
            .25em 0 0 $white,
            .5em 0 0 $white;}
  60% {
    text-shadow:
            .25em 0 0 $text-blue,
            .5em 0 0 $white;}
  80%, 100% {
    text-shadow:
            .25em 0 0 $text-blue,
            .5em 0 0 $text-blue;}
}
