.button {
  background: $white;
  box-shadow: 0 0 0 0 rgba($green-text, 0);
  border-radius: 3px;
  font-weight: bold;
  font-size: 14px;
  @include transition(all, 0.2s);
  svg {
    @include transition(all, 0.2s);
    path {
      @include transition(all, 0.2s);
    }
  }
  &.button-green {
    border: 1px solid $green-text;
    color: $green-text;
    &:hover {
      border: 2px solid $green-text;
      box-shadow: none;
    }
    &:focus {
      border: 2px solid rgba(14, 125, 125, 0.3);
      box-shadow: none;
    }
    &:active {
      border: 2px solid rgba(14, 125, 125, 0.3);
      box-shadow: none;
    }
  }
  &.button-dark-green {
    color: $white;
    background-color: $green-text;
    box-shadow: 0 4px 5px -4px rgba(0, 0, 0, 0.05);
    &:hover {
      background-color: $deep-sea-green;
      border-color: $deep-sea-green;
      box-shadow: 0 4px 5px -4px rgba(0, 0, 0, 0.05);
    }
    &:focus {
        background-color: $deep-sea-green;
        border-color: $deep-sea-green;
        box-shadow: 0 4px 5px -4px rgba(0, 0, 0, 0.05);
    }
    &:active {
      background-color: $deep-sea-green;
      box-shadow: 0 0 0 3px $fountain-blue, 0 4px 5px -4px rgba(0, 0, 0, 0.05);
    }
  }
  &.button-close {
    border: solid 1px transparent;
    box-shadow: none;
    background: none;
    @include transition(all, 0.2s);
    &:hover, &:active, &:focus {
      background-color: rgba($red-text, .1);
      svg {
        rect {
          fill: $red-text;
        }
      }
    }
    &:active, &:focus {
      border: solid 1px $red-text;
    }
  }
  &.button-edit {
    border: solid 1px transparent;
    box-shadow: none;
    background: none;
    &:hover, &:active, &:focus {
      color: $text-blue;
      background-color: rgba($text-blue, .1);
    }
    &:active, &:focus {
      border: solid 1px $text-blue;
    }
    svg {
      margin: 4px 0 0 0;
    }
  }
}
