$white: #FFF;

$base-color: #3B3B3B;
$gray-text: #9D9D9D;
$dark-gray: #444;
$light-gray: #E5E5E5;
$green-text: #0E7D7D;
$light-green: #00D06C;
$green-border: #009688;
$dark-green: #0A6161;
$red-text: #FF5B5B;
$text-blue: #3F88C5;
$gray-bg: #F7F7F7;
$deep-sea-green: #085A5A;
$pine-green: #158B8B;
$rose-white: #EAEFFB;
$picton-blue: #5CA4DA;
$steel-blue: #457BA3;
$scandal-color: #B7D8D8;
$fountain-blue: #68b9b9;
$glacier: #87BEBE;
